import queryString from 'query-string';

type SessionContext = Partial<{
    L1: string;
    L2: string;
    L3: string;
}>;

const UNKNOWN_QUERY_PARAM_VALUE = 'unknown';
export const useSessionContext = (): SessionContext => {
    try {
        const queryParams = queryString.parse(document.location?.search || '') || {};
        const result: SessionContext = {
            L1: (queryParams['L1'] as string) || UNKNOWN_QUERY_PARAM_VALUE,
            L2: (queryParams['L2'] as string) || UNKNOWN_QUERY_PARAM_VALUE,
            L3: (queryParams['L3'] as string) || UNKNOWN_QUERY_PARAM_VALUE,
        };

        return result;
    } catch {
        return {};
    }
};
