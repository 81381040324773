import React, { FunctionComponent } from 'react';
import { TweekProvider as Provider } from 'react-tweek';
import { observer } from 'mobx-react-lite';
import { tweekStore } from '../stores/TweekStore';

interface TweekProviderProps {
    homeUserId?: string;
}

export const TweekProvider: FunctionComponent<TweekProviderProps> = observer(({ children }) => {
    const tweekRepository = tweekStore.tweekRepo;
    return <Provider value={tweekRepository}>{children}</Provider>;
});
