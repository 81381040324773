import { TweekRepository } from 'tweek-local-cache';
import { createTweekClientWithFallback, ITweekClient, IdentityContext } from 'tweek-client';
import { observable, action } from 'mobx';
import { mergeDeep } from 'immutable';

const TWEEK_URL = 'https://tweek.mysoluto.com';
const TWEEK_FALLBACK_URL = 'https://tweek-west.mysoluto.com';

export type HomeUserContext = IdentityContext &
    Partial<{
        MessagingLinkFlow: string;
    }>;

export type UserBrowserContext = IdentityContext &
    Partial<{
        FlowId: string;
    }>;

export type Context = {
    home_user: HomeUserContext;
    user_browser: UserBrowserContext;
};

class TweekStore {
    @observable public tweekRepo: TweekRepository;
    @observable private tweekContext: Context;

    private tweekClient: ITweekClient;
    private readonly INITIAL_CONTEXT: Context = {
        home_user: { id: '' },
        user_browser: { ud: '' },
    };

    constructor() {
        this.tweekClient = createTweekClientWithFallback({
            urls: [TWEEK_URL, TWEEK_FALLBACK_URL],
            requestTimeoutInMillis: 6000,
            useLegacyEndpoint: true,
        });

        this.tweekContext = this.INITIAL_CONTEXT;

        this.tweekRepo = new TweekRepository({
            client: this.tweekClient,
            context: this.tweekContext,
        });
    }

    @action
    updateContext(updates: Partial<Context>): void {
        this.tweekContext = mergeDeep(this.tweekContext, { ...updates });
        this.tweekRepo.updateContext(this.tweekContext);
    }

    getFromTweek<T>(key: string): Promise<T> {
        return this.tweekRepo.getValue<T>(key);
    }
}

export const tweekStore = new TweekStore();
