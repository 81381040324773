export enum LogLevel {
    debug = 'debug',
    info = 'info',
    warning = 'warning',
    error = 'error',
}
export type LoggerExtraData = any;

export interface LogFunction {
    (message: string): Promise<void>;
    (message: string, extraData: LoggerExtraData): Promise<void>;
    (message: string, logThreshold: LogLevel): Promise<void>;
    (message: string, logThreshold: LogLevel, extraData: LoggerExtraData): Promise<void>;
}

export type Logger = { [P in LogLevel]: LogFunction } & {
    enrichLogs: (extraData: LoggerExtraData) => void;
};
