import { configure } from 'mobx';
configure({ isolateGlobalState: true });

import './services/sdk-loader';
import './index.css';
import React, { FunctionComponent } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IVRFlow, GenericMessaging , IsAliveRouter} from './pages';
import { AnalyticsProvider, TweekProvider } from './providers';

declare global {
    const GLOBALS: {
        APP_ENVIRONMENT: 'development' | 'production';
    };
}

const App: FunctionComponent = () => {
    return (
        <AnalyticsProvider>
            <TweekProvider>
                <Router>
                    <Switch>
                        <Route exact path='/askmyexpert' component={IVRFlow} />
                        <Route exact path='/' component={GenericMessaging} />
                        <Route exact path='/isAlive'component={IsAliveRouter}/>
                    </Switch>
                </Router>
            </TweekProvider>
        </AnalyticsProvider>
    );
};

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
