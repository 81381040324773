import queryString from 'query-string';
const queryParams = queryString.parse(document.location?.search || '');

const appKey = (queryParams['appKey'] as string) || 'aa740c79-a700-4bfe-900e-2fb456656151';
const branch = queryParams['branch'] as string;

let sdkUrlPrefix = `https://assets.ae-sdk.io/core/v3`;
const sdkUrlSuffix = `?appKey=${appKey}`;

if (branch) sdkUrlPrefix += `/branch/${branch}`;
const scriptUrl = `${sdkUrlPrefix}${sdkUrlSuffix}`;

const w = window,
    a = '__AE_SDK_ACTIONS',
    d = document,
    s = d.createElement('script', {}),
    b = d.getElementsByTagName('script')[0];
w[a] = [];
(w as any).AE_SDK = function (_) {
    w[a].push(_);
};
s.type = 'text/javascript';
s.async = true;
s.defer = true;
s.src = scriptUrl;
b?.parentNode?.insertBefore(s, b.nextSibling);
