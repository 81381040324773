const loggerExtraData: any = {};
let ms: any;

try {
    const bowser = require('bowser');
    const agentDetails = bowser.getParser(window.navigator.userAgent);
    loggerExtraData.osType = agentDetails.getOS().name;
    loggerExtraData.osVersion = agentDetails.getOS().version;
    loggerExtraData.browserType = agentDetails.getBrowser().name;
    loggerExtraData.browserVersion = agentDetails.getBrowser().version;
    loggerExtraData.isMobile = agentDetails.getPlatformType().toLowerCase() === 'mobile';
} catch { }

try {
    ms = require('ms');
} catch { }

let TIMEZONE: number = 'unknown' as any;
try {
    TIMEZONE = (new Date().getTimezoneOffset() / 60) * -1;
} catch {
} finally {
    loggerExtraData.timezone = TIMEZONE;
}

const PAGE_ENTRY_TIME: number = Date.now();

function getTimeOnPage(): Record<string, unknown> {
    try {
        const now = Date.now();
        const timeDiff = now - PAGE_ENTRY_TIME;
        return {
            timeOnPage: ms(timeDiff, { long: true }),
            timeOnPageInMinutes: Math.floor(timeDiff / 1000 / 60),
        };
    } catch {
        return { timeOnPage: `${Date.now() - PAGE_ENTRY_TIME} ms` };
    }
}

export const getStaticExtraData = (): Record<string, unknown> => {
    const extraData = { ...loggerExtraData, ...getTimeOnPage() };

    extraData.host = window.location.host;
    extraData.fullUrl = window.location.href.split('?')[0];
    extraData.pathName = window.location.pathname;

    return extraData;
};
