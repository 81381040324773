import React, { FunctionComponent } from 'react';
import { createRootDispatcher } from 'shisell';
import { createScoped } from 'shisell/extenders';
import analyticsWriter from '../services/analytics-writer';
import { AnalyticsProvider as ShisellAnalyticsProvider } from 'react-shisell';

const analyticsDispatcher = createRootDispatcher(analyticsWriter).extend(createScoped('AESDK'));

export const AnalyticsProvider: FunctionComponent = (props) => {
    return <ShisellAnalyticsProvider dispatcher={() => analyticsDispatcher}>{props.children}</ShisellAnalyticsProvider>;
};
