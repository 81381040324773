import { LoggedInEventArgs, SessionChangedEventArgs } from "@anywhere-expert-sdk/types";
import React, { useCallback, useEffect } from "react";
import { FunctionComponent } from "react";
import { useAnalytics } from "react-shisell";
import { AE_SDK_CONTAINER_ID, LandingPage } from '../../components/LandingPage';
import { useAESDKInitialization } from "../../hooks/useAESDKInitialization";
import { useSessionContext } from "../../hooks/useSessionContext";
import { withMeta, withIdentity, withExtras } from 'shisell/extenders';
import { getSDKHandler } from '../../services/promisified-ae-sdk';
import { getQueryParams } from '../../services/query-params-provider';
import { logger } from "../../services/logger";
import { getToken } from "../../services/persistent-token-storage";

export const GenericMessaging: FunctionComponent = () => {
    const analytics = useAnalytics();
    const initSDK = useAESDKInitialization();
    const sessionContext = useSessionContext();

    const onSessionChanged = useCallback(
        (args: SessionChangedEventArgs) => {
            if (args.action !== 'created') return;
            window.AE_SDK((_, sdk) => {
                sdk.sendMessage({
                    type: 'CustomerMessage',
                    hidden: true,
                    message: `Customer Context:
                    \nL1=${sessionContext.L1}
                    \nL2=${sessionContext.L2}
                    \nL3=${sessionContext.L3}`,
                });
            });
        },
        [sessionContext]
    );

    useEffect(() => {
        const onLoggedIn = (args: LoggedInEventArgs): void => {
            analytics
                .dispatcher
                .extend(
                    withMeta('HasSessionContext', true),
                    withIdentity('HomeUserId', args.homeUserId),
                    withExtras({ L1: sessionContext.L1, L2: sessionContext.L2, L3: sessionContext.L3, IsTest: false })
                )
                .dispatch('SessionContext')
        };

        window.AE_SDK((_, sdk) => {
            sdk.events.addListener('LOGGED_IN', onLoggedIn);
        });

        return () => {
            window.AE_SDK((_, sdk) => {
                sdk.events.removeListener('LOGGED_IN', onLoggedIn);
            });
        }
    }, [analytics]);

    useEffect(() => {
        window.AE_SDK((_, sdk) => {
            sdk.events.addListener('SESSION_CHANGED', onSessionChanged);
        });

        return () => {
            window.AE_SDK((_, sdk) => {
                sdk.events.removeListener('SESSION_CHANGED', onSessionChanged);
            });
        };
    }, [onSessionChanged]);

    useEffect(() => {
        initSDK({ containerElementId: AE_SDK_CONTAINER_ID })
            .then(async () => {
                const sdk = await getSDKHandler()();
                const { mdn } = getQueryParams<{ mdn: string }>();
                const authenticationTokenFromOtherFlows = getToken();
                try {
                    await sdk.auth.login({ mdn, token: authenticationTokenFromOtherFlows! });
                } catch {
                    await sdk.auth.login({ mdn });
                }
            })
            .catch(error => {
                logger.error(error);
            });
    }, []);

    return <LandingPage />;
};
