import axios from 'axios';
import { AnalyticsEventModel } from 'shisell';

const client = axios.create({
    baseURL: 'https://prodhome1analyticslistener.mysoluto.com',
    headers: {
        'X-Api-Client': 'anywhere-expert-sdk',
        'X-Api-Client-Version': '1.0.0',
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

const analyticsWriter = async (eventModel: AnalyticsEventModel): Promise<void> => {
    if (GLOBALS.APP_ENVIRONMENT === 'development') {
        console.debug('[landing-page-analytic]', eventModel.Name, eventModel);
        return;
    }

    await client.post('/api/v1/Events/', eventModel);
};

export default analyticsWriter;
