import axios, { AxiosRequestConfig } from 'axios';

const bodyToEncodedBodyString = (body: unknown): string =>
    Object.keys(body as any)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent((body as any)[key])}`)
        .join('&');

const BASE_BODY_REQUEST = {
    grant_type: 'home-user-access-token-from-temporary-code',
    scope: 'home-user-resource',
    client_id: 'home-user-token',
    client_secret: 'ab944171-b2ab-4526-b94b-cfad5f1e45a1',
};

const AUTHENTICATION_API_URL = 'https://homeuserauth.mysoluto.com/connect/token';

export const REQUEST_OPTIONS: Partial<AxiosRequestConfig> = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    withCredentials: true,
    validateStatus: (_) => _ === 200,
};

export const getHomeTokenFromTemporaryCode = async (code: string): Promise<string> => {
    const encodedBody = bodyToEncodedBodyString({ ...BASE_BODY_REQUEST, code });

    const response = await axios.post<{ access_token: string }>(AUTHENTICATION_API_URL, encodedBody, REQUEST_OPTIONS);
    return response.data.access_token;
};
