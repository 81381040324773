import { InitializationSettings } from '@anywhere-expert-sdk/types';
import { useCallback } from 'react';
import { getSDKHandler } from '../services/promisified-ae-sdk';

export const useAESDKInitialization: () => (initSettings: InitializationSettings) => Promise<void> = () => {
    const initFunction = useCallback(async (initSettings) => {
        const sdk = await getSDKHandler()();
        await sdk.initialize(initSettings);
    }, []);

    return initFunction;
};
