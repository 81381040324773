import axios from 'axios';
import asyncRetry from 'async-retry';
import merge from 'deepmerge';
import { serializeError } from 'serialize-error';
import { getStaticExtraData } from './safe-static-logger-extra-data-enricher';
import { LogLevel, Logger, LoggerExtraData } from './types';

export const LOGGING_API_URL: string = 'https://logging-api-new.mysoluto.com';

const URL: string = `${LOGGING_API_URL}/api/v1/log/`;

const LogLevelToComparableValue = (logLevel: LogLevel): number => {
    switch (logLevel) {
        case LogLevel.debug:
            return 1;
        case LogLevel.info:
            return 2;
        case LogLevel.warning:
            return 3;
        case LogLevel.error:
            return 4;
        default:
            return 1;
    }
};

let enrichedExtraData: LoggerExtraData = {};
function enrichLogs(extraData: LoggerExtraData): void {
    enrichedExtraData = merge(enrichedExtraData, serializeError(extraData), {
        arrayMerge: (_, __) => __,
        clone: true,
    });
}

async function logInternal(logLevel: LogLevel, message: string, extraData: LoggerExtraData): Promise<void> {
    const headers = { 'Content-Type': 'application/json' };
    const formattedExtraData =
        logLevel === LogLevel.error
            ? !!extraData.error
                ? extraData
                : { error: extraData ?? 'undefined' }
            : typeof extraData === 'object'
                ? extraData
                : { extraData };

    let formattedMessage: string = message;
    if (typeof message !== 'string') {
        formattedExtraData['message'] = message;
        formattedMessage = '';
    }

    const combinedExtraData: any = merge(getStaticExtraData(), merge(enrichedExtraData, serializeError(formattedExtraData)));

    if (GLOBALS.APP_ENVIRONMENT === 'development') {
        console.debug(`[landing-page-logger.${logLevel}]`, formattedMessage, combinedExtraData);
        return;
    }

    const body = {
        Data: { 'extra-data': combinedExtraData, message_str: message },
        Timestamp: new Date().toISOString(),
        ApplicationName: 'ATT-AE-SDK-LandingPage',
        Content: formattedMessage,
    };

    await asyncRetry((_, __) => axios.post(URL + logLevel, body, { headers }));
}

async function log(logLevel: LogLevel, message: string, extraData?: LoggerExtraData): Promise<void>;
async function log(logLevel: LogLevel, message: string, logThreshold?: LogLevel): Promise<void>;
async function log(logLevel: LogLevel, message: string, logThreshold?: LogLevel, extraData?: LoggerExtraData): Promise<void>;
async function log(
    logLevel: LogLevel,
    message: string,
    arg1?: LoggerExtraData | LogLevel,
    arg2?: LoggerExtraData
): Promise<void> {
    try {
        let extraData: LoggerExtraData;
        let shouldFilterLogLevel: boolean = false;
        const logThreshold: LogLevel = LogLevel[arg1 as LogLevel];
        if (!!logThreshold) {
            extraData = arg2 as LoggerExtraData;
            shouldFilterLogLevel = true;
        } else {
            extraData = arg1 as LoggerExtraData;
        }

        if (shouldFilterLogLevel) {
            const logLevelValue = LogLevelToComparableValue(logLevel);
            const logThresholdValue = LogLevelToComparableValue(logThreshold);
            if (logLevelValue < logThresholdValue) return;
        }

        await logInternal(logLevel, message, extraData);
    } catch (error) {
        console.warn('AnywhereExpertSDK', 'error sending log', error);
    }
}

export const logger: Logger = {
    debug: (message: any, arg1?: any, arg2?: any) => log(LogLevel.debug, message, arg1, arg2),
    info: (message: any, arg1?: any, arg2?: any) => log(LogLevel.info, message, arg1, arg2),
    warning: (message: any, arg1?: any, arg2?: any) => log(LogLevel.warning, message, arg1, arg2),
    error: (message: any, arg1?: any, arg2?: any) => log(LogLevel.error, message, arg1, arg2),
    enrichLogs,
};
