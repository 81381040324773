import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { BreakPoints } from '../break-points';
import { useAnalytics } from 'react-shisell';
import { createScoped } from 'shisell/extenders';
import 'react-shisell';
const logoUrl = require('../resources/att-asurion-logo.svg');

export const AE_SDK_CONTAINER_ID = 'ae-sdk-container';

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-family: Apercu Pro;
`;

const HeaderBackground = styled.div`
    display: none;
    height: 130px;
    width: 100%;
    background-color: #449dd5;

    @media ${BreakPoints.desktop} {
        display: flex;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    box-sizing: border-box;
    width: 100%;

    @media ${BreakPoints.desktop} {
        display: flex;
        max-width: 950px;
        padding-bottom: 100px;
        padding-top: 30px;
        width: 70%;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 32px;
    width: 100%;
    background-color: #ffffff;
    z-index: 99;
    box-sizing: border-box;
    flex-basis: 70px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    flex-shrink: 0;

    @media ${BreakPoints.desktop} {
        flex-basis: 100px;
    }
`;

const SDKContainer = styled.div`
    width: 100%;
    position: absolute;
    top: 70px;
    bottom: 0;
    flex-grow: 1;
    z-index: 98;

    @media ${BreakPoints.desktop} {
        top: 130px;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
    }
`;

export const LandingPage: FunctionComponent = () => {
    const analytics = useAnalytics();

    useEffect(() => {
        analytics.dispatcher.extend(createScoped('ATT_LandingPage')).dispatch('View');
    }, []);

    return (
        <MainContainer>
            <HeaderBackground />
            <ContentContainer>
                <Header>
                    <img src={logoUrl} />
                </Header>
                <SDKContainer id={AE_SDK_CONTAINER_ID} />
            </ContentContainer>
        </MainContainer>
    );
};
